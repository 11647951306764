import * as React from "react";
import styled from "styled-components";
import PageWrapper from "../components/shared/PageWrapper";
import ContentDiv from "../components/shared/ContentDiv";
import {
  LocalStorageInterface as LSI,
  ScreenSizes,
  configureLogoutTimer,
  createLogOutTimer,
  isSmallScreen,
  parseActiveAccount,
} from "../shared-code";
import backgroundImage from "../images/web-bg.jpg";
import { AccountInfo } from "../components/DataModels";
import ErrorPopover from "../components/shared/ErrorPopover";

const StyledTextContainer = styled.div`
  max-width: 1025px;
  padding-inline: 10px;

  & h1 {
    font-size: 30px;
    font-weight: 600;
    line-height: normal;
    margin-block: 30px;
    @media (max-width: ${ScreenSizes.mobile}) {
      text-align: center;
    }
  }

  & h2 {
    font-size: 26px;
    font-weight: 600;
    line-height: normal;
    margin-block: 20px;
    padding-top: 30px;

    &.toc {
      @media (max-width: ${ScreenSizes.mobile}) {
        text-align: center;
      }
    }
  }

  & p {
    padding-bottom: 40px;
  }

  & p,
  ol,
  ul,
  span {
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 1px;
    @media (max-width: ${ScreenSizes.mobile}) {
      letter-spacing: normal;
    }
  }

  & .bold {
    font-weight: 600;
    font-size: 22px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 40px 0;
  }
`;

const TableOfContents = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 120%;
  letter-spacing: 1px;

  & ::before {
    content: "■ ";
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
`;

interface MyState {
  accounts: AccountInfo[] | undefined;
  activeAccountId: string | undefined;
  timeOutNumber: number | undefined;
  errorMessage: string | undefined;
  errorCode: string | undefined;
  errorMessageShown: boolean;
}

export default class Faq extends React.Component<{}, MyState> {
  state: MyState = {
    accounts: LSI.getAccounts(),
    activeAccountId: LSI.getActiveAccountId(),
    timeOutNumber: undefined,
    errorMessage: undefined,
    errorCode: undefined,
    errorMessageShown: false,
  };

  componentDidMount(): void {
    window.addEventListener("focus", () => this.setupTimeoutNumber());
    this.setupTimeoutNumber();
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<MyState>
  ): void {
    if (prevState.accounts == undefined && this.state.accounts != undefined) {
      this.setupTimeoutNumber();
    }
  }

  componentWillUnmount(): void {
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
  }

  setupTimeoutNumber(): void {
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
    const timeOutNumber = configureLogoutTimer();
    if (timeOutNumber != undefined) {
      this.setState({ timeOutNumber: timeOutNumber });
    }
  }

  render() {
    const activeAccount = parseActiveAccount(
      this.state.accounts,
      this.state.activeAccountId
    );
    return (
      <PageWrapper
        subHeader={null}
        activeRoute=""
        fullScreen={false}
        forceLogin={false}
        backgroundImage={backgroundImage}
        accounts={this.state.accounts}
        activeAccount={activeAccount}
        updateAccounts={(accounts: AccountInfo[]): void => {
          this.setState({ accounts: accounts });
        }}
        updateActiveAccount={(account: AccountInfo): void => {
          this.setState({ activeAccountId: account.accountId });
        }}
        removeAccountsAndActive={() =>
          this.setState({ accounts: undefined, activeAccountId: undefined })
        }
        showLogin={undefined}
        errorFn={(message, code) =>
          this.setState({
            errorMessage: message,
            errorCode: code,
            errorMessageShown: true,
          })
        }
      >
        <ErrorPopover
          errorMessageShown={this.state.errorMessageShown}
          errorMessage={this.state.errorMessage}
          errorCode={this.state.errorCode}
          closeFn={() => this.setState({ errorMessageShown: false })}
        />
        <ContentDiv
          smallDevice={isSmallScreen()}
          hasSubHeader={true}
          fullScreen={false}
        >
          <StyledTextContainer>
            <h1>FREQUENTLY ASKED QUESTIONS</h1>
            <h2 className="toc">TABLE OF CONTENTS</h2>
            <TableOfContents>
              <a href="#account">ACCOUNT</a>
              <a href="#aircraft">AIRCRAFT</a>
              <a href="#black-market">BLACK MARKET</a>
              <a href="#controllers">CONTROLLERS</a>
              <a href="#egs">EPIC GAME STORE</a>
              <a href="#game-modes">GAME MODES</a>
              <a href="#flight-pass">FLIGHT PASS</a>
              <a href="#purchases">IN-APP PURCHASES</a>
              <a href="#network">NETWORK</a>
              <a href="#settings">SETTINGS</a>
            </TableOfContents>
            <h2 id="account">ACCOUNT</h2>
            <p>
              <span className="bold">
                Will my progress carry over if I switch devices, or play on
                another platform?
              </span>
              <br />
              <br />
              Yes! As long as you have a registered Starform ID associated with
              your Metalstorm account, you can use your login on any supported
              platforms and carry all your progress and purchases with you.
            </p>
            <p>
              <span className="bold">How do I register a guest account?</span>
              <br />
              <br />
              In order to register your guest account to ensure you don't lose
              any progress the process is quite simple. From the main hangar,
              open up the menu and click Starform ID. From there, click
              Settings, and then Register Guest Account. Type in your email
              address, enter the verification code, and you're all set!
            </p>
            <p>
              <span className="bold">Can I change my email address?</span>
              <br />
              <br />
              Unfortunately, we do not offer a way to change email addresses.
            </p>
            <p>
              <span className="bold">
                I lost my old account, what can I do?
              </span>
              <br />
              <br />
              If you got a new device, or are failing to log in to an account
              you previously played on please reach out to our support team at{" "}
              <u>
                <a href="mailto:support@starform.co">support@starform.co</a>
              </u>{" "}
              so they may be able to assist you.
            </p>
            <h2 id="aircraft">AIRCRAFT</h2>
            <p>
              <span className="bold">How do I unlock more aircraft?</span>
              <br />
              <br />
              As you upgrade your planes your Hangar XP will move up. Each
              Hangar Level also has plane unlock requirements. Once both are met
              you will move up Hangar Levels eventually up to 20 over time.
              Aircraft can be unlocked via Aircraft Credits, gold, or a
              combination of both.
            </p>
            <p>
              <span className="bold">
                Why did I lose aircraft credits and gold upon unlocking my last
                plane?
              </span>
              <br />
              <br />
              If you're short on Aircraft Credits you can buy through the
              remaining amount in gold equivalent. For example: Say an aircraft
              is 100 credits, but you only have 50. You can purchase the rest
              for 300 gold. Please note, doing so uses both the 50 credits and
              gold amount so both will be reduced after the transaction is
              complete.
            </p>
            <p>
              <span className="bold">
                How do I unlock my plane's special ability?
              </span>
              <br />
              <br />
              In order to unlock each plane's special ability, they need to be
              upgraded to at least Plane Level 8. If you have the required
              blueprint and silver, you can then craft and equip the ability to
              use in-game. Each ability has 3 levels to upgrade requiring 1/2/5
              blueprints(8 total).
            </p>
            <p>
              <span className="bold">What ways can I earn blueprints?</span>
              <br />
              <br />
              Blueprints can be given a number of ways. There are drop chances
              in crates(event + squad as well), possible daily offers in the
              shop, and our blueprint sales on certain weekends.
            </p>
            <p>
              <span className="bold">How do I unlock and equip mods?</span>
              <br />
              <br />
              In order to unlock access to mods you must first reach at least
              Plane Level 16 to unlock the first slot. A second becomes
              available at Plane Level 20. Once any plane meets these
              requirements you may start to see tokens and materials you can use
              to equip to plane's that have at least one slot unlocked. Please
              keep in mind that these are unlocked and equipped on an individual
              basis, but the resource pool is universal. So if you unlock and
              upgrade a mod on your F-5, you will have to recraft and equip that
              mod on your F-100.
            </p>
            <p>
              <span className="bold">
                What ways can I unlock mod tokens and abilities?
              </span>
              <br />
              <br />
              Mod Tokens and Abilities are similar to earn as blueprints. They
              have a higher drop chance in crates(event + squad as well), and
              can appear in daily offers too.
            </p>

            <h2 id="black-market">BLACK MARKET</h2>
            <p>
              <span className="bold">What is the Black Market?</span>
              <br />
              <br />
              The Black Market is the event we use to introduce new planes to
              the game. It is 5 days in length where you can earn an event crate
              and discount towards the plane.
            </p>
            <p>
              <span className="bold">How do I get the discount?</span>
              <br />
              <br />
              You have 5 days to complete the Black Market challenges and earn
              up to 50% off the bundle which includes: the event plane unlocked
              at level 8, its special ability, and a rare paint. After the event
              is over you have a 24 hour grace period to claim your discount.
            </p>
            <p>
              <span className="bold">What do all the checkboxes mean?</span>
              <br />
              <br />
              The Black Market event has 3 categories of challenges to progress
              in order to level up: Dedication, Teamwork, and Skill. These
              checkboxes show if you're currently meeting requirements in each
              of the categories. You'll see this reflected at the home screen,
              the event page, and even in your hangar as you scroll through.
              Note: All categories progress individually so you don't have to
              worry about checking all 3 boxes before playing a match.
            </p>
            <p>
              <span className="bold">What are roles again?</span>
              <br />
              <br />
              The Dedication challenges cycle between roles based on what
              aircraft roles you have unlocked in your hangar. These being:
              Light, Medium, Attack, Interceptor, and Heavy. For a quick
              reference you can go to your hangar, and right above your plane's
              name you'll see an icon with a color behind it. If you're ever
              unclear you can always click into a specific plane and it'll show
              the role written out in text right underneath the name. For the
              purpose of this event- Keep an eye out for a purple check mark as
              you're scrolling through your hangar as a quick alternative!
            </p>
            <p>
              <span className="bold">What happens if I miss a day?</span>
              <br />
              <br />
              Nothing! You can catch up on all event progress before it ends on
              the 5th day.
            </p>

            <h2 id="controllers">CONTROLLERS</h2>
            <p>
              <span className="bold">
                Does Metalstorm have controller support?
              </span>
              <br />
              <br />
              Metalstorm does have controller support whether it's a mobile
              gaming controller, your general bluetooth controller, or even
              joysticks!
            </p>
            <p>
              <span className="bold">How do I pair my controller?</span>
              <br />
              <br />
              In order to start using your controller with Metalstorm you're
              going to need to do a couple quick things. First step would be to
              connect/plug in your input controller to your device. Once
              connected, after you reach the hangar in Metalstorm, you'll want
              to go to Settings, and select the Controller input. That's it!
            </p>
            <p>
              <span className="bold">How do I set my bindings?</span>
              <br />
              <br />
              From Settings, simply go to Bindings, and start setting bindings
              to your liking. We suggest experimenting around in a few custom
              matches to get a good feel.
            </p>
            <p>
              <span className="bold">
                What type of controller support does Metalstorm offer?
              </span>
              <br />
              <br />
              What type of controller support does Metalstorm offer? Metalstorm
              offers partial controller support. You may notice you're unable to
              use your controller in the menus, this is by design. The expected
              experience for now is using your device for menus, and the
              controller once you're in game.
            </p>

            <h2 id="egs">EPIC GAME STORE</h2>
            <p>
              <span className="bold">
                Is Metalstorm available on Epic Game Store?
              </span>
              <br />
              <br />
              We are, but Metalstorm is not officially launched in all countries
              at this time.
            </p>
            <p>
              <span className="bold">How do I get the Epic Reward items?</span>
              <br />
              <br />
              In order to claim the Epic Rewards, you will need to make sure you
              are launching Metalstorm through the Epic Game Store launcher. If
              you've linked your Epic and Metalstorm accounts together, all you
              have to do is sign in, go to the shop, and start claiming your
              free items.
            </p>
            <p>
              <span className="bold">
                How do I link my Metalstorm account with Epic?
              </span>
              <br />
              <br />
              Upon launching Metalstorm from Epic for the first time, you will
              be asked to accept an account link request. If you've declined
              this, or are unsure - you can verify this from the following. From
              the Epic Games launcher, click on your profile in the upper right
              corner, then click Account. A link will open up on your internet
              browser, and you'll now want to scroll down the list and select
              Apps & Accounts. From this menu, click Apps. If you see Metalstorm
              listed, you're all set. If you're having issues claiming your
              items you can also remove this link, and it'll ask you to
              reauthorize the next time you launch Metalstorm.
            </p>

            <h2 id="game-modes">GAME MODES</h2>
            <p>
              <span className="bold">
                What game modes does Metalstorm offer?
              </span>
              <br />
              <br />
              Metalstorm offers a selection of modes. In standard matchmaking,
              our playlist alternates between Team Deathmatch and Air
              Superiority on different maps. In Custom Games, we have 2
              experimental modes: Elimination + VIP Deathmatch.
            </p>
            <p>
              <span className="bold">
                Why is my team's counter not moving up in Air Superiority?
              </span>
              <br />
              <br />
              In Air Superiority points are granted to whichever team is
              controlling more capture points. Kills do not contribute to your
              team's tally, but they may open up windows for your team to
              capture and control more points around the map. If your team holds
              2 or more capture points at a time, you will see your team's tally
              move up.
            </p>
            <h2 id="flight-pass">FLIGHT PASS</h2>
            <p>
              <span className="bold">
                What items are included with the purchase of the Flight Pass?
              </span>
              <br />
              <br />
              Each Flight Pass comes with two tracks full of rewards to earn:
              Free + Premium. Purchasing the Flight Pass provides you with
              access to the Premium track rewards, but please note you still
              have to progress and earn them in order to claim them.
            </p>
            <p>
              <span className="bold">
                What happens to items I have not collected at the end of a
                Flight Pass?
              </span>
              <br />
              <br />
              Items that you have earned up to but not collected will be
              automatically redeemed once the new Flight Pass begins. You won't
              lose anything you've earned. Rewards you have not progressed up to
              however will be gone so it is important to keep track of the
              remaining time left of each Flight Pass before purchasing.
            </p>
            <p>
              <span className="bold">
                How come I am not receiving any progress?
              </span>
              <br />
              <br />
              Progress is earned towards the Flight Pass by completing matches
              in standard matchmaking, and by completing missions. Custom
              Matches do not count towards this.
            </p>
            <p>
              <span className="bold">What is Squad Silver?</span>
              <br />
              <br />
              Squad Silver is a little bonus incentive for your squadmates. For
              each player in your squad that purchases the Flight Pass, you can
              claim some Silver up to 10 times per Flight Pass. You can grant
              additional Silver for Flight Passes purchased through the
              exclusive web-offer at{" "}
              <u>
                {" "}
                <a href="https://playmetalstorm.com/shop">
                  https://playmetalstorm.com/shop
                </a>
              </u>
              .
            </p>

            <h2 id="purchases">IN-APP PURCHASES</h2>
            <p>
              <span className="bold">
                What types of storefronts does Metalstorm support?
              </span>
              <br />
              <br />
              Metalstorm offers a wide variety of options depending on what
              version and platform of the game you are playing on? For
              officially supported countries: iOS App Store, Android Google Play
              Store, Epic Games Store, and Xsolla for website purchases. For
              beta versions of the app your only option will be Xsolla. Payment
              options may vary by storefront.
            </p>
            <p>
              <span className="bold">
                How do I make in-app purchases from the website?
              </span>
              <br />
              <br />
              In order to make Metalstorm purchases online, you'll first need to
              have a Metalstorm account. If you have an account, go to{" "}
              <u>
                {" "}
                <a href="https://playmetalstorm.com/shop">
                  https://playmetalstorm.com/shop
                </a>
              </u>
              , and then proceed to sign in with your same Metalstorm
              credentials. After signing in and visiting the shop, you should
              see your Metalstorm purchase options, as well as some
              web-exclusive offers. Please note at this time that not all offers
              are available to purchase on the web at this time.
            </p>
            <p>
              <span className="bold">What are Star Points?</span>
              <br />
              <br />
              Star Points are a web-exclusive currency to reward players who
              make purchases on{" "}
              <u>
                {" "}
                <a href="https://playmetalstorm.com/shop">
                  https://playmetalstorm.com/shop
                </a>
              </u>
              . Each purchase made through the web shop will net you Star Points
              which you can redeem for select rewards in game. Who doesn't like
              free things!?
            </p>
            <p>
              <span className="bold">
                I think I was double-charged, how can I verify this?
              </span>
              <br />
              <br />
              Things like pending or failed transactions may factor into this,
              but a lot of app stores like to bundle purchases on receipts. For
              more precise breakdowns of what you are being charged [and from
              where], we highly recommend checking your charges against your app
              store transaction history for itemized breakdowns. A $20 purchase
              could appear on a $40 receipt because other items were bundled
              together from recent transactions.
            </p>

            <h2 id="network">NETWORK</h2>
            <p>
              <span className="bold">
                Why does my game keep downloading content?
              </span>
              <br />
              <br />
              You will see this notification at the top of your screen whenever
              there's new content being unpackaged on the app. This may be as
              you're loading a new plane, a playlist update, switching decals, a
              friend joining the lobby, etc. This is a normal behavior to expect
              while playing.
            </p>
            <p>
              <span className="bold">
                What does the spinning bar mean whenever I do certain actions?
              </span>
              <br />
              <br />
              If you click on something in the game, and you see a little
              spinning bar in the middle of your screen you may be having some
              network issues. The causes may vary from pilot to pilot, but for
              further assistance we recommend reaching out to our support team
              at{" "}
              <u>
                <a href="mailto:support@starform.co">support@starform.co</a>
              </u>{" "}
              so they may be able to better troubleshoot your situation.
            </p>
            <p>
              <span className="bold">
                What does the orange metered connection mean when flying?
              </span>
              <br />
              <br />
              Similar to the above, this is another indicator for networking
              issues. When you're in the middle of a match you may even get
              kicked and auto-rejoined when our system detects this. The hope
              here is that we can relaunch you back into the simulation in a
              better networking state. We recommend reaching out to{" "}
              <u>
                <a href="mailto:support@starform.co">support@starform.co</a>
              </u>{" "}
              if this is a frequent behavior for you.
            </p>
            <p>
              <span className="bold">
                How can I check my network connectivity?
              </span>
              <br />
              <br />
              There's a couple key things you can reference for your connection
              within Metalstorm. The first being the bar of information located
              at the bottom of your screen while you are in matches. This is
              enabled by default, but if you've turned this off you can toggle
              it back on by going into Advanced Settings and enabling Game
              Performance. From this information you can see real-time ping as
              well as factors that might indicate packet loss. To expand on
              ping, if you start creating a Custom Match you can actually check
              your connection vs all the servers via Custom game Settings.
            </p>

            <h2 id="settings">SETTINGS</h2>
            <p>
              <span className="bold">
                How can I hide my email address when loading in?
              </span>
              <br />
              <br />
              You can hide your email address from showing during loading by
              opening up the Menu {">"} Settings {">"} General. From there,
              scroll down to the very bottom and enable the toggle called “Hide
              Email Address”.
            </p>
          </StyledTextContainer>
        </ContentDiv>
      </PageWrapper>
    );
  }
}
